import type { FeatureLike } from 'ol/Feature';

import { Box, Portal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import classes from './ExcludedAreasTooltip.module.css';

interface ExcludedAreasTooltipProps {
  data: {pixel: number[];features: FeatureLike[];} | null;
  target?: string | HTMLElement;
}

export const ExcludedAreasTooltip = (props: ExcludedAreasTooltipProps): JSX.Element => {
  const { data, target } = props;
  const { t } = useTranslation();

  return (
    <Portal target={target}>
      {data &&
      <Box left={data.pixel[0]} top={data.pixel[1]} mah={`calc(100vh - ${data.pixel[1]}px)`} className={classes.areaTooltip}>
          {data.features.map((item) => {
          const dateFrom = item.get('dateFrom');
          const dateTo = item.get('dateTo');

          return (
            <div key={item.getId()} className={classes.areaTooltipItem}>
                <Text size='sm'>{item.get('name')}</Text>
                <Text size='sm'>
                  {dateFrom && dateTo ?
                t('intlDateTimeRange', {
                  value: [new Date(dateFrom), new Date(dateTo)],
                  dateStyle: 'short',
                  timeStyle: 'short'
                }) :
                t('map.emptyDate')}
                </Text>
              </div>);

        })}
        </Box>}

    </Portal>);

};